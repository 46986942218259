import { regexErrorMessages, regexPatterns } from './regexHelper';
import {
    blogEntityDescription,
    blogEntityName,
    blogEntityTitle,
    articleIndexStatus,
    articlePublishedStatus,
    articleCanonical,
    articleSlug,
    articleLanguage,
    entityRedirectUrl,
    slugEntityBreadcrumbDisplayName,
} from '@helpers/AccessibleFormHelperConstants';
import { BlogFormHelper } from '@models/blogForm';
import { Category } from '@models/category';
import { EntityName } from '@models/entity';
import { regexMatchesOrIsEmpty } from '@helpers/validators';
import { breadcrumbDisplayNameValidator } from './validationRules';

const friendlyName = 'Category';

export const categoryFormHelper: BlogFormHelper = {
    name: 'Category',
    entityName: EntityName.Category,
    apiControllerName: 'category',
    hasMediaField: false,
    fields: [
        {
            id: 'category-language',
            key: 'language',
            name: 'Language',
            required: true,
            type: 'select',
            list: 'languages',
            listItemValueKey: 'id',
            readOnlyModes: ['edit'],
            formHelper: articleLanguage(friendlyName),
        },
        {
            id: 'category-name',
            key: 'name',
            name: 'Category Name',
            required: true,
            maxLength: 100,
            type: 'text',
            formHelper: blogEntityName(friendlyName),
        },
        {
            id: 'category-title',
            key: 'title',
            name: 'SEO Title',
            required: true,
            type: 'text',
            formHelper: blogEntityTitle(friendlyName),
        },
        {
            id: 'category-description',
            key: 'description',
            name: 'SEO Description',
            required: true,
            type: 'text',
            formHelper: blogEntityDescription(friendlyName),
        },
        {
            id: 'category-slug',
            key: 'slug',
            name: 'Url Slug',
            validators: [regexMatchesOrIsEmpty(regexPatterns.slug, regexErrorMessages.slug)],
            required: true,
            maxLength: 100,
            type: 'text',
            sanitizationConfiguration: {
                autoTrim: true,
                lowercaseOnChange: true,
            },
            formHelper: articleSlug(friendlyName),
        },
        {
            id: 'category-breadcrumbDisplayName',
            key: 'breadcrumbDisplayName',
            name: 'Breadcrumb Display Name',
            validators: [breadcrumbDisplayNameValidator],
            required: false,
            type: 'text',
            formHelper: slugEntityBreadcrumbDisplayName(friendlyName),
        },
        {
            id: 'category-canonical',
            key: 'canonical',
            name: 'Canonical Url',
            required: false,
            type: 'text',
            sanitizationConfiguration: {
                autoTrim: true,
            },
            formHelper: articleCanonical(friendlyName),
        },
        {
            id: 'category-redirectUrl',
            key: 'redirectUrl',
            name: 'Redirect Url',
            required: false,
            maxLength: 5000,
            type: 'text',
            sanitizationConfiguration: {
                autoTrim: true,
            },
            formHelper: entityRedirectUrl(friendlyName),
        },
        {
            id: 'category-noIndex',
            key: 'noIndex',
            name: 'No Index',
            required: false,
            type: 'checkbox',
            formHelper: articleIndexStatus(friendlyName),
        },
        {
            id: 'category-isHidden',
            key: 'isHidden',
            name: 'Hidden',
            required: false,
            type: 'checkbox',
            formHelper: {
                name: 'Hidden',
                helperText:
                    'Toggle whether or not the category will be shown in the blog pages when displaying all categories.',
            },
        },
        {
            id: 'category-publishedDate',
            key: 'publishedDateUtc',
            name: 'Published',
            type: 'publishBox',
            formHelper: articlePublishedStatus(friendlyName),
        },
    ],
};

export const categoryModel: Partial<Category> = {
    language: 1,
    name: '',
    slug: '',
    canonical: '',
    noIndex: false,
    status: 0,
};
