import { SoftwarePackage } from '@models/softwarePackge';
import { colors, v2Colors } from '@web-for-marketing/react-ui';

export const enSoftwarePackages: SoftwarePackage = {
    seo: {
        title: 'Software Packages | Geotab',
        description: 'Compare features and choose your desired plan for telematics and fleet management.',
    },
    bannerContent: {
        title: 'Software Packages',
        description: 'Compare features and choose your desired plan.',
    },
    softwarePackagesComparisonTable: {
        title: 'Plan Comparisons',
        subHeaders: ['Productivity', 'Fleet Optimization', 'Compliance', 'Safety', 'Expandability'],
        showLessFeatures: 'Show less features',
        showMoreFeatures: 'Show more features',
        footNotes: [
            '*Certain features may experience delays on the Base and Pro plans due to data, network, or processing limitations.',
            '**Some Base plan features may be limited due to lack of support for engine status data.',
            '***Light-duty vehicles,USA and Canada only, with fleet-wide utilization of the ProPlus plan. Other conditions apply.',
            '****For Base and Regulatory, only braking based rules and exceptions are available. Audible alerts are not available.',
        ],
        buttons: {
            planDetail: {
                text: 'See plan details',
                link: 'https://docs.google.com/document/d/1KOupT7RNKmvx4sBZhD0VQG__TZXwiwryEs_mFcwpiZs/edit',
            },
            contact: {
                text: 'Contact Sales',
                link: '/contact-us/sales-inquiry/',
            },
        },
        plansAvailability: {
            base: true,
            regulatory: true,
            pro: true,
            proPlus: true,
        },
        baseDetails: [
            {
                index: 0,
                cols: 1,
                title: 'Base',
                color: v2Colors.core.innovation,
                subTitle: 'Worldwide',
                description:
                    'The Base plan offers GPS location, VIN, Driver ID, basic IOX support and harsh braking detection.',
            },
            {
                index: 1,
                cols: 1,
                title: 'Regulatory',
                color: colors.core.webEssentials.red,
                subTitle: 'USA and Canada',
                description:
                    'The Regulatory plan adds Hours of Service, IFTA and Temperature Monitoring functionality over the Base plan. It is geared towards assisting fleets in meeting a range of compliance regulations.',
            },
            {
                index: 2,
                cols: 1,
                title: 'Pro',
                color: v2Colors.core.geotab,
                subTitle: 'Worldwide',
                description:
                    'The Pro plan offers the functionality of the Regulatory plan and adds support for engine, accelerometer data and EV data.',
            },
            {
                index: 3,
                cols: 1,
                title: 'ProPlus',
                color: colors.core.webEssentials.green,
                subTitle: 'Worldwide',
                description:
                    'The ProPlus plan offers the greatest functionality, including Active Tracking, a lifetime warranty, and premium services including EV data.',
            },
        ],
        baseDataDetails: [
            {
                index: 0,
                title: '',
                iconColor: v2Colors.core.innovation,
                data: ['GPS location', 'VIN', 'Driver ID', 'Basic IOX support', 'Harsh braking detection'],
            },
            {
                index: 1,
                title: 'All Base features plus',
                iconColor: colors.core.webEssentials.red,
                data: [
                    'Hours of Service',
                    'IFTA',
                    'Temperature monitoring',
                    'Helps fleets meet compliance regulations',
                ],
            },
            {
                index: 2,
                title: 'All Regulatory features plus',
                iconColor: v2Colors.core.geotab,
                data: ['Engine data', 'Accelerometer data', 'EV Support'],
            },
            {
                index: 3,
                title: 'All Pro features plus',
                iconColor: colors.core.webEssentials.green,
                data: ['Active Tracking', 'Lifetime warranty', 'Advanced IOX support', 'Geotab Data Connector'],
            },
        ],
        productivityPlans: [
            {
                title: 'Instant GPS tracking using patented curve based algorithms for optimal recording',
                base: true,
                baseAsterisk: '',
                regulatory: true,
                regulatoryAsterisk: '',
                pro: true,
                proAsterisk: '',
                proPlus: true,
                proPlusAsterisk: '',
            },
            {
                title: 'Highly flexible Rules and exception engine — customizable and editable',
                base: true,
                baseAsterisk: '**',
                regulatory: true,
                regulatoryAsterisk: '**',
                pro: true,
                proAsterisk: '',
                proPlus: true,
                proPlusAsterisk: '',
            },
            {
                title: 'Driver congregation reporting',
                base: true,
                baseAsterisk: '',
                regulatory: true,
                regulatoryAsterisk: '',
                pro: true,
                proAsterisk: '',
                proPlus: true,
                proPlusAsterisk: '',
            },
            {
                title: 'Locating closest vehicle by address',
                base: true,
                baseAsterisk: '',
                regulatory: true,
                regulatoryAsterisk: '',
                pro: true,
                proAsterisk: '',
                proPlus: true,
                proPlusAsterisk: '',
            },
            {
                title: 'Driver ID via NFC',
                base: true,
                baseAsterisk: '',
                regulatory: true,
                regulatoryAsterisk: '',
                pro: true,
                proAsterisk: '',
                proPlus: true,
                proPlusAsterisk: '',
            },
            {
                title: 'Message and send routes to drivers with compatible Garmin PNDs',
                boldTitlePrefix: '',
                base: false,
                baseAsterisk: '',
                regulatory: true,
                regulatoryAsterisk: '',
                pro: true,
                proAsterisk: '',
                proPlus: true,
                proPlusAsterisk: '',
            },
            {
                title: 'Geotab Data Connector, bring curated data into popular BI Tools (also available as paid Add-In)',
                boldTitlePrefix: '',
                base: true,
                baseAsterisk: '',
                baseColorOverride: v2Colors.core.smoke,
                regulatory: true,
                regulatoryColorOverride: v2Colors.core.smoke,
                regulatoryAsterisk: '',
                pro: true,
                proColorOverride: v2Colors.core.smoke,
                proAsterisk: '',
                proPlus: true,
                proPlusAsterisk: '',
            },
        ],
        fleetOptimizationPlans: [
            {
                title: 'Vehicle maintenance reminders scheduled by time or distance',
                base: true,
                baseAsterisk: '',
                regulatory: true,
                regulatoryAsterisk: '',
                pro: true,
                proAsterisk: '',
                proPlus: true,
                proPlusAsterisk: '',
            },
            {
                title: 'Vehicle maintenance reminders scheduled by mileage or engine hours',
                base: false,
                baseAsterisk: '',
                regulatory: false,
                regulatoryAsterisk: '',
                pro: true,
                proAsterisk: '',
                proPlus: true,
                proPlusAsterisk: '',
            },
            {
                title: 'Fuel Management Platform — Track fuel ups, fuel drain, fuel theft, fuel card integration, etc.',
                base: false,
                baseAsterisk: '',
                regulatory: false,
                regulatoryAsterisk: '',
                pro: true,
                proAsterisk: '',
                proPlus: true,
                proPlusAsterisk: '',
            },
            {
                title: 'Engine status data for all major engine protocols (fuel usage/voltage/coolant/temp.)',
                base: false,
                baseAsterisk: '',
                regulatory: false,
                regulatoryAsterisk: '',
                pro: true,
                proAsterisk: '',
                proPlus: true,
                proPlusAsterisk: '',
            },
            {
                title: 'Easy device installation with Self-Calibrating Accelerometer',
                base: false,
                baseAsterisk: '',
                regulatory: false,
                regulatoryAsterisk: '',
                pro: true,
                proAsterisk: '',
                proPlus: true,
                proPlusAsterisk: '',
            },
            {
                title: 'Record and interpret fault data (J1939, 1708, OBD, CAN)',
                base: false,
                baseAsterisk: '',
                regulatory: false,
                regulatoryAsterisk: '',
                pro: true,
                proAsterisk: '',
                proPlus: true,
                proPlusAsterisk: '',
            },
            {
                title: 'Geotab Roadside Add-In for complimentary towing, locksmith, battery boost, fuel delivery, flat tire change, & more.***',
                base: false,
                baseAsterisk: '',
                regulatory: false,
                regulatoryAsterisk: '',
                pro: false,
                proAsterisk: '',
                proPlus: true,
                proPlusAsterisk: '',
            },
        ],
        compliancePlans: [
            {
                title: 'Device tampering detection',
                base: true,
                baseAsterisk: '',
                regulatory: true,
                regulatoryAsterisk: '',
                pro: true,
                proAsterisk: '',
                proPlus: true,
                proPlusAsterisk: '',
            },
            {
                title: 'DVIR compliance via Geotab Drive (Android/iOS)',
                base: true,
                baseAsterisk: '',
                regulatory: true,
                regulatoryAsterisk: '',
                pro: true,
                proAsterisk: '',
                proPlus: true,
                proPlusAsterisk: '',
            },
            {
                title: 'Driver Application (Geotab Drive) scalable for future needs',
                base: true,
                baseAsterisk: '',
                regulatory: true,
                regulatoryAsterisk: '',
                pro: true,
                proAsterisk: '',
                proPlus: true,
                proPlusAsterisk: '',
            },
            {
                title: 'IFTA miles recording',
                base: true,
                baseAsterisk: '**',
                regulatory: true,
                regulatoryAsterisk: '',
                pro: true,
                proAsterisk: '',
                proPlus: true,
                proPlusAsterisk: '',
            },
            {
                title: 'HOS compliance via Geotab Drive (Android/iOS) and Garmin',
                base: false,
                baseAsterisk: '',
                regulatory: true,
                regulatoryAsterisk: '',
                pro: true,
                proAsterisk: '',
                proPlus: true,
                proPlusAsterisk: '',
            },
            {
                title: 'Privacy Mode',
                base: true,
                baseAsterisk: '',
                regulatory: true,
                regulatoryAsterisk: '',
                pro: true,
                proAsterisk: '',
                proPlus: true,
                proPlusAsterisk: '',
            },
        ],
        safetyPlans: [
            {
                title: 'Audible alerts and notifications for speeding, idling, and driver identification',
                boldTitlePrefix: 'Basic driver coaching -- ',
                base: true,
                baseAsterisk: '',
                regulatory: true,
                regulatoryAsterisk: '',
                pro: true,
                proAsterisk: '',
                proPlus: true,
                proPlusAsterisk: '',
            },
            {
                title: 'Audible alerts and notifications for harsh braking, sharp cornering, over-acceleration, seat belt use, vehicle in reverse, over-revving, etc. plus basic driver coaching and customizable server-side rules and exceptions',
                boldTitlePrefix: 'Advanced driver coaching --',
                base: true,
                baseAsterisk: '****',
                regulatory: true,
                regulatoryAsterisk: '****',
                pro: true,
                proAsterisk: '****',
                proPlus: true,
                proPlusAsterisk: '',
            },
        ],
        expandabilityPlans: [
            {
                title: 'Web-based software reporting platform (SaaS) capable of supporting unlimited vehicles and users',
                boldTitlePrefix: '',
                base: true,
                baseAsterisk: '',
                regulatory: true,
                regulatoryAsterisk: '',
                pro: true,
                proAsterisk: '',
                proPlus: true,
                proPlusAsterisk: '',
            },
            {
                boldTitlePrefix: '',
                title: 'Support of multiple map types',
                base: true,
                baseAsterisk: '',
                regulatory: true,
                regulatoryAsterisk: '',
                pro: true,
                proAsterisk: '',
                proPlus: true,
                proPlusAsterisk: '',
            },
            {
                title: 'Rich reporting functionality — editable, customizable, trends, etc.',
                base: true,
                baseAsterisk: '**',
                regulatory: true,
                regulatoryAsterisk: '**',
                pro: true,
                proAsterisk: '',
                proPlus: true,
                proPlusAsterisk: '',
            },
            {
                title: 'Customizable dashboards',
                base: true,
                baseAsterisk: '',
                regulatory: true,
                regulatoryAsterisk: '',
                pro: true,
                proAsterisk: '',
                proPlus: true,
                proPlusAsterisk: '',
            },
            {
                title: 'Flexible user access control',
                base: true,
                baseAsterisk: '',
                regulatory: true,
                regulatoryAsterisk: '',
                pro: true,
                proAsterisk: '',
                proPlus: true,
                proPlusAsterisk: '',
            },
            {
                title: 'MyGeotab access via Android and iOS',
                base: true,
                baseAsterisk: '',
                regulatory: true,
                regulatoryAsterisk: '',
                pro: true,
                proAsterisk: '',
                proPlus: true,
                proPlusAsterisk: '',
            },
            {
                title: 'SDK and APIs that are open, free, and easy to use',
                base: true,
                baseAsterisk: '',
                regulatory: true,
                regulatoryAsterisk: '',
                pro: true,
                proAsterisk: '',
                proPlus: true,
                proPlusAsterisk: '',
            },
            {
                title: 'Access partner Add-Ons and solutions through Geotab Marketplace',
                base: true,
                baseAsterisk: '',
                regulatory: true,
                regulatoryAsterisk: '',
                pro: true,
                proAsterisk: '',
                proPlus: true,
                proPlusAsterisk: '',
            },
            {
                boldTitlePrefix: 'Basic',
                title: 'IOX Add-On (IOX-GOTALK, IOX-AUXM, IOX-BUZZ, IOX-NFCREADERA, etc.) integration with in-vehicle peripherals and devices',
                base: true,
                baseAsterisk: '',
                regulatory: true,
                regulatoryAsterisk: '',
                pro: true,
                proAsterisk: '',
                proPlus: true,
                proPlusAsterisk: '',
            },
            {
                boldTitlePrefix: 'Advanced',
                title: 'IOX Add-On integration with in-vehicle peripherals and devices (IOX-BT, IOX-USB, IOX-CAN, IOX-WRKS, and Add-Ons for substance spreader controllers IOX-RS232M/F/D)',
                base: false,
                baseAsterisk: '',
                regulatory: false,
                regulatoryAsterisk: '',
                pro: false,
                proAsterisk: '',
                proPlus: true,
                proPlusAsterisk: '',
            },
            {
                title: 'Active Tracking — premium quality tracking solution that delivers location information with higher frequency and precision',
                base: false,
                baseAsterisk: '',
                regulatory: false,
                regulatoryAsterisk: '',
                pro: false,
                proAsterisk: '',
                proPlus: true,
                proPlusAsterisk: '',
            },
            {
                title: 'EV driving energy used',
                base: false,
                baseAsterisk: '',
                regulatory: false,
                regulatoryAsterisk: '',
                pro: true,
                proAsterisk: '',
                proPlus: true,
                proPlusAsterisk: '',
            },
            {
                title: 'EV battery charge % (SOC)',
                base: false,
                baseAsterisk: '',
                regulatory: false,
                regulatoryAsterisk: '',
                pro: true,
                proAsterisk: '',
                proPlus: true,
                proPlusAsterisk: '',
            },
            {
                title: 'EV charging status',
                base: false,
                baseAsterisk: '',
                regulatory: false,
                regulatoryAsterisk: '',
                pro: true,
                proAsterisk: '',
                proPlus: true,
                proPlusAsterisk: '',
            },
            {
                title: 'EV charging energy',
                base: false,
                baseAsterisk: '',
                regulatory: false,
                regulatoryAsterisk: '',
                pro: true,
                proAsterisk: '',
                proPlus: true,
                proPlusAsterisk: '',
            },
            {
                title: 'Limited lifetime device warranty',
                base: false,
                baseAsterisk: '',
                regulatory: false,
                regulatoryAsterisk: '',
                pro: false,
                proAsterisk: '',
                proPlus: true,
                proPlusAsterisk: '',
            },
        ],
    },
};
