import { getPrefixStringWithBaseUrlFunction } from '@helpers/string';
import { AppInitialData } from '@models/initialData';
import { AuthenticationSettings } from '@models/authentication';
import { StaticValues } from '@models/staticValues';

export const getStaticValues = (
    baseUrl: string,
    mediaUrlPrefix: string,
    generalFileUrlPrefix: string,
    myAdminBaseApiUrl: string,
    authenticationSettings: AuthenticationSettings,
    initialData: AppInitialData,
    fullUrl: string | null
): StaticValues => ({
    page: initialData.page,
    videoPageData: initialData.videoPageData,
    document: initialData.document,
    prefixStringWithBaseUrl: getPrefixStringWithBaseUrlFunction(baseUrl, mediaUrlPrefix, generalFileUrlPrefix),
    pressRelease: {
        homepageData: initialData.pressReleaseHomePageData,
        articleData: initialData.pressReleaseArticlePageData,
    },
    whitePaper: {
        homePageData: initialData.whitePaperHomePageData,
        articleData: initialData.whitePaperArticlePageData,
    },
    blog: {
        allCategories: initialData.blogCategories || [],
        homePageData: initialData.blogHomePageData,
        articleData: initialData.blogArticlePageData,
        authorPageData: initialData.authorPageData,
        categoryPageData: initialData.categoryPageData,
    },
    successStories: {
        homePageData: initialData.successStoryHomePageData,
        articleData: initialData.successStoryArticlePageData,
    },
    glossary: {
        articleData: initialData.glossaryArticlePageData,
    },
    sitemap: initialData.sitemap,
    myGeotabLoginPageData: initialData.myGeotabLoginPageData,
    baseUrl: baseUrl || '',
    mediaUrlPrefix: mediaUrlPrefix || '',
    generalFileUrlPrefix: generalFileUrlPrefix || '',
    myAdminBaseApiUrl: myAdminBaseApiUrl || '',
    authenticationSettings: authenticationSettings,
    pagedSearchResult: initialData.pagedSearchResult,
    searchSelectedEntityTypes:
        initialData.pagedSearchResult?.entityTypes && !initialData.pagedSearchResult.isAllType
            ? initialData.pagedSearchResult.entityTypes
            : [],
    fullUrl: fullUrl || '',
    tagPageData: initialData.tagPageData,
    invalidSearch: initialData.invalidSearch,
    breadcrumbs: initialData.breadcrumbs || [],
    isPageBreadcrumbDisabled: initialData.page?.breadcrumbDisabled || false,
    isRegionBreadcrumbDisabled: initialData.breadcrumbDisabledSetting,
    recaptchaSiteKey: initialData.recaptchaSiteKey,
    hrefLangGroup: initialData.hrefLangGroup || null,
});
